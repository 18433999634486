<template>
    <b-card no-body class="p-1">
        <b-overlay :show="state.loading">
            <div class="demo-spacing-0 m-1"
                 dir="ltr"
            >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <search-and-filter
                :is-active.sync="isActive"
                :options="columns"
                @filter="getData(1, perPage)"
                @reset="getData(1, perPage)"/>

            <div class="relative-over-x">
                <b-table
                    ref="refUserListTable"
                    :items="items"
                    :small="true"
                    responsive
                    :fields="columns"
                    primary-key="id"
                    show-empty
                    empty-text="اطلاعاتی یافت نشد"
                    @sort-changed="sort($event)"
                    no-local-sort
                    style="white-space: nowrap; min-height : 235px"
                >

                    <template #cell(tracking_code)="data">
                        <span dir="ltr">
                            {{ data.item.tracking_code }}
                        </span>
                    </template>

                    <template #cell(channel)="data">
                        <span>
                            {{ data.item.channel }}
                        </span>
                    </template>
                    <template #cell(card_number)="data">
                        <span dir="ltr">
                            {{ data.item.card_number }}
                        </span>
                    </template>
                    <template #cell(amount)="data">
                        <span dir="ltr">
                            {{ data.item.amount }}
                        </span>
                    </template>
                    <template #cell(wallet)="data">
                        <span dir="ltr">
                            {{ data.item.wallet }}
                        </span>
                    </template>
                    <template #cell(result)="data">
                        <BBadge :variant="( data.item.result === 'تراکنش موفق' ? 'success':'warning')">
                            {{ data.item.result }}
                        </BBadge>
                    </template>
                    <template #cell(payment_date)="data">
                        <span>
                            {{ data.item.payment_date }}
                        </span>
                    </template>
<!--                    <template #cell(transactionType)="data">-->
<!--                        <b-badge-->
<!--                            pill-->
<!--                            :variant="'light-'+typeVariant(data.item.tracking_code)"-->
<!--                        >-->
<!--                            {{typeLabel(data.item.transactionType)}}-->
<!--                        </b-badge>-->
<!--                    </template>-->



<!--                    <template #cell(amount)="data">-->
<!--                        <span dir="ltr">-->
<!--                            {{ $toLocal(data.item.amount,$decimal[data.item.relatedCoin]) }}-->
<!--                        </span>-->
<!--                    </template>-->


<!--                    <template #cell(transactionId)="data">-->
<!--                        <b-link :href="data.item.viewInExplorer || '#'" target="_blank">{{-->
<!--                                data.item.transactionId-->
<!--                            }}-->
<!--                        </b-link>-->
<!--                    </template>-->

<!--                    <template #cell(createdAtDateTime)="data">-->
<!--                        <span dir="ltr">-->
<!--                            {{ $G2J(data.item.createdAtDateTime) }}-->
<!--                        </span>-->
<!--                    </template>-->


                </b-table>
            </div>

            <!-- pagination -->
            <div
                class="demo-spacing-0 d-flex justify-content-between m-1"
                dir="rtl"
            >
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    align="left"
                    @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                        id="perpage"
                        v-model="perPage"
                        dir="rtl"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                        style="min-width: 85px"
                        @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import { BButton, BCard,  BOverlay, BBadge,BPagination, BTable} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
    name: "VandarTransactions",
    components: {
        SearchAndFilter,
        BPagination,
        BCard,
        BBadge,
        BButton,
        BTable,
        // BDropdown,
        // BDropdownItem,
        vSelect,
        BOverlay,
    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 10,
        sortBy: '',
        isSortDirDesc: '',
        userData: [],
        isActive: false,
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        columns: [
            {
                label: 'کانال ',
                key: 'channel',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'شماره پیگیری',
                key: 'tracking_code',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'شماره کارت',
                key: 'card_number',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'مقدار',
                key: 'amount',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'موجودی',
                key: 'wallet',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'وضعیت',
                key: 'result',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'تاریخ تراکنش',
                key: 'payment_date',
                sortable: false,
                searchType: 'text'
            },
            // {
            //     label: 'نوع انتقال',
            //     key: 'transactionType',
            //     sortable: true,
            //     searchType: 'select',
            //     selectOptions: [
            //         {label: 'برداشت', value: 'WITHDRAW'},
            //         {label: 'واریز', value: 'DEPOSIT'},
            //     ]
            // },
            // {
            //     label: 'مقدار انتقال',
            //     key: 'amount',
            //     sortable: true,
            //     searchType: 'number'
            // },
            // {
            //     label: 'آدرس مقصد / مبدا',
            //     key: 'Address',
            //     sortable: false,
            // },
            // {
            //     label: 'هش لینک',
            //     key: 'transactionId',
            //     sortable: false,
            // },
            // {
            //     label: 'تاریخ درخواست',
            //     key: 'createdAtDateTime',
            //     sortable: true,
            //     searchType: 'date'
            // },
        ],
    }),
    methods: {
        async getData(page, perPage) {
            this.state.loading = true
            const queryParams = {
                pageSize: perPage,
                page: page,
                ...this.$route.query
            }
            let res = await this.$axios.get(
                '/wallets/vandar-transactions',
                {
                    params: queryParams
                })
            res = res.data
            this.state.loading = false
            this.items = res.data

            // this.currentPage = res.data.number + 1
            this.currentPage = res.current_page
            this.rows = res.total
        },
        sort(e) {
            console.log(e)

            let sort = this.$toSnakeCase(e.sortBy)
            // let sort = e.sortBy
            let sorting = e.sortDesc ? 'DESC' : 'ASC'

            this.$router.push({
                query: {
                    ...this.$route.query,
                    orderBy: sort,
                    sorting: sorting
                }
            })

            this.getData(1, this.perPage)

        },
    },
    mounted() {
        this.getData();
    }
}
</script>

<style scoped>

</style>